import React from 'react';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from "axios";

class ResearchAreas extends React.Component {

    constructor() {
        super();

        this.state = {
            researchArea: [],
            loading : true
        };
    }

    componentDidMount = () => {
        try {
            axios.get(window.config.DEV_LINK + "/linhas_tematicas/getById?idUi=" + window.config.id).then((response) => {
                this.setState({
                    researchArea: response.data.payload.combinedResults,
                    loading : false
                });
            });

        } catch (error) {
            console.error(error);
        }
    };

    render() {

        let researchArea = this.state.researchArea;
        let sdg = window.config.SDG;
        let loading = this.state.loading;
        return (

            <>
            {loading &&
                <div className="preloader">
                    <div className="main-circle">
                        <div className="green-circle">
                            <div className="brown-circle">

                            </div>
                        </div>
                    </div>
                </div>

            }
                {researchArea &&
                    <section className="blog-detail pt-121">
                        <div className="container">
                            {Object.values(researchArea).map((resArea) => (
                                <div key={resArea.ID}>
                                    <div className='row'>
                                        <div className="col-lg-12" align="justify">
                                            <div className="section-title">
                                                <h4>{resArea.DESCR_BREVE}</h4>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ placeContent: "center" }}>
                                        <div className="col-lg-12" align="justify">
                                            <div className="section-title" >
                                                <p>{resArea.DESCR}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {window.config.RESEARCH_AREAS_ASSOCIATION === 1 &&
                                    <>
                                    <div className="row" style={{ placeContent: "left" }}>
                                        <div className='col-6'>
                                        {resArea.RESPONSAVEL_INFO.map((user) => (
                                                    <div className="research-areas-image" key={user.ID}>
                                                        <p>
                                                            <b>{user.nome_website} </b>
                                                        </p>
                                                        <p>{"Coordinator of " + resArea.DESCR_BREVE}</p>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="row" style={{ placeContent: "left", marginBottom: "30px" }}>    
                                        <div className='col-6'>
                                            <div className='resservice'>
                                                <div className='resitem' style={{textAlign: 'left'}}>    
                                                <Link
                                            to={{
                                                pathname: "/resareaPeople/" + (resArea.ID),
                                                state: {
                                                    resID: resArea.ID,
                                                    
                                                },
                                            }}
                                        >
                                                    <a>Members of this research area</a>
                                                    </Link> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                            ))}
                            {sdg &&
                            <div className='row'>
                                <div className='col-12'>
                                    
                                    <div className='sdg'>

                                            {sdg.map((number) => (
                                                <img
                                                    key={number}
                                                    src={`https://adit.ipvc.pt/modulosipvc_producao/frontend/images/sdg/${number}.png`}
                                                    alt={`SDG ${number}`}
                                                    style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }}
                                                />
                                                ))}
                                    </div>
                                </div>
                            </div>
                            }
                            </div>
                    </section>
                }

            </>

        );
    }
}

export default withRouter(ResearchAreas);