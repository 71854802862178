import React from "react";
import axios from "axios";
import SingleMember from "./SingleMember";
import { withRouter } from "react-router-dom";
import { fetchPosts } from "../../data/getUser";

class TeamDetailsInner extends React.Component {
  constructor() {
    super();

    this.state = {
      user: [],
      usertheses: [],
      posts: [],
    };
  }

  componentDidMount = async () => {
    let directPageLink = window.location.href.split("/")[4];

    try {
      let response;
      if (isNaN(directPageLink)) {
        response = await axios.get(
          `${window.config.DEV_LINK}/investigador/getByUsername/${directPageLink}`
        );
      } else {
        response = await axios.get(
          `${window.config.DEV_LINK}/investigador/getById/${directPageLink}`
        );
      }

      this.setState({
        user: response.data.payload[0],
      });

      if (this.props.location.state) {
        this.setState({
          posts: this.props.location.state.posts,
        });
      } else {
        const fetchThePosts = await fetchPosts();
        this.setState({
          posts: fetchThePosts,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.user &&
      this.state.user.ORCID_ID && // Ensure ORCID_ID exists
      prevState.user !== this.state.user &&
      !this.state.usertheses.length // Only fetch if usertheses is not already populated
    ) {
      const ORCID_ID = this.state.user.ORCID_ID;
      axios
        .get(`${window.config.DEV_LINK}/teses_ipvc/orcid/${ORCID_ID}`)
        .then((response) => {
          // Handle the response from the ORCID API
          this.setState({
            usertheses: response.data.payload,
          });
        })
        .catch((error) => {
          console.error("Error fetching ORCID_ID data:", error);
        });
    }
  }

  render() {
    const user = this.state.user;
    const posts = this.state.posts;
    const theses = this.state.usertheses;

    return (
      <>
        <section className="team-detail pt-120 ">
          <SingleMember
            user={user}
            id={user.ID}
            posts={posts}
            theses={theses}
          />
        </section>
      </>
    );
  }
}

export default withRouter(TeamDetailsInner);
