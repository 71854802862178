import React, { useState, useEffect, useCallback } from "react";
import Pagination from "../../data/PaginationFrame.js";
import ProjectPosts from "../../data/ProjectPosts.js";
import axios from "axios";
import { Checkbox } from "antd";
import { Slider } from "@mui/material";

const ProjectV2 = () => {
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([
    "National",
    "International",
  ]);
  const [selectedEmcursoFilters, setSelectedEmcursoFilters] = useState([
    "Ongoing",
    "Completed",
  ]);
  const [value, setValue] = useState([2015, new Date().getFullYear()]);
  const [currentPage, setCurrentPage] = useState(1);
  const [projects, setProjects] = useState([]);
  const [nationalCount, setNationalCount] = useState(0);
  const [internationalCount, setInternationalCount] = useState(0);

  const publicationPerPage = 20;
  const isStatusValid = selectedEmcursoFilters.length > 0;
  const isLocationValid = selectedFilters.length > 0;

  const fetchCounts = useCallback(async () => {
    try {
      const nacionalResponse = await axios.get(
        "https://adit.ipvc.pt/modulosipvc_producao/rest-api-slimv3/api/proxy/",
        {
          params: { nacional: true, id_ui: window.config.bolseirosUiId },
        }
      );
      setNationalCount(nacionalResponse.data.data.length);

      const internacionalResponse = await axios.get(
        "https://adit.ipvc.pt/modulosipvc_producao/rest-api-slimv3/api/proxy/",
        {
          params: { nacional: false, id_ui: window.config.bolseirosUiId },
        }
      );
      setInternationalCount(internacionalResponse.data.data.length);
    } catch (error) {
      console.error("Failed to fetch counts:", error);
    }
  }, []);

  const fetchProjects = useCallback(async () => {
    setLoading(true);
    try {
      const nacionalValues = [];
      if (selectedFilters.includes("National")) nacionalValues.push(true);
      if (selectedFilters.includes("International")) nacionalValues.push(false);

      if (nacionalValues.length === 0) {
        setProjects([]);
        setLoading(false);
        return;
      }

      // Handle emCurso filter logic
      let emCursoParam = "%";
      if (selectedEmcursoFilters.length === 1) {
        emCursoParam = selectedEmcursoFilters.includes("Ongoing")
          ? "true"
          : "false";
      } else if (selectedEmcursoFilters.length === 0) {
        emCursoParam = "none"; // Or handle empty state as needed
      }

      const requests = nacionalValues.map((nacional) =>
        axios.get(
          "https://adit.ipvc.pt/modulosipvc_producao/rest-api-slimv3/api/proxy/",
          {
            params: {
              nacional,
              emCurso: emCursoParam,
              id_ui: window.config.bolseirosUiId,
            },
          }
        )
      );

      const responses = await Promise.all(requests);
      const combinedData = responses.flatMap(
        (response) => response.data?.data || []
      );
      setProjects(combinedData);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    } finally {
      setLoading(false);
    }
  }, [selectedFilters, selectedEmcursoFilters]);

  useEffect(() => {
    fetchCounts();
  }, [fetchCounts]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    // Trigger initial filter when component mounts
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only once on mount

  const filterData = useCallback(() => {
    const filtered = projects
      .filter((project) => {
        const startYear = new Date(project.start_date).getFullYear();
        return startYear >= value[0] && startYear <= value[1];
      })
      .sort((a, b) => {
        return (
          new Date(b.start_date).getFullYear() -
          new Date(a.start_date).getFullYear()
        );
      });
    setFilteredData(filtered);
  }, [projects, value]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const handleCheckboxChange = (checkedValues) => {
    setSelectedFilters(checkedValues);
    setCurrentPage(1);
  };

  const handleEmcursoChange = (checkedValues) => {
    setSelectedEmcursoFilters(checkedValues);
    setCurrentPage(1);
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1);
  };

  const currentPublication = filteredData.slice(
    (currentPage - 1) * publicationPerPage,
    currentPage * publicationPerPage
  );

  const marks = [
    { value: 2015, label: "2015" },
    {
      value: new Date().getFullYear(),
      label: new Date().getFullYear().toString(),
    },
  ];

  console.log("CURRPUB", currentPublication);

  return (
    <div className="project p-120">
      {loading ? (
        <div className="preloader">
          <div className="main-circle">
            <div className="green-circle">
              <div className="brown-circle"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <div className="card">
                <div className="card-header-mine">
                  <h5>Filters</h5>
                </div>
                <div className="filter-section">
                  <Checkbox.Group
                    options={[
                      {
                        label: `National (${nationalCount})`,
                        value: "National",
                      },
                      {
                        label: `International (${internationalCount})`,
                        value: "International",
                      },
                    ]}
                    value={selectedFilters}
                    onChange={handleCheckboxChange}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "8px",
                    }}
                  />
                </div>
                <hr style={{ marginBottom: "0" }}></hr>
                <div className="filter-section">
                  <Checkbox.Group
                    options={[
                      { label: "Ongoing", value: "Ongoing" },
                      { label: "Completed", value: "Completed" },
                    ]}
                    value={selectedEmcursoFilters}
                    onChange={handleEmcursoChange}
                    defaultValue={["Ongoing", "Completed"]}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "8px",
                      marginBottom: "8px",
                    }}
                  />
                </div>
                <div className="card-body-two" style={{ textAlign: "center" }}>
                  <Slider
                    value={value}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    min={2015}
                    max={new Date().getFullYear()}
                    marks={marks}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-10">
              {!isStatusValid && !isLocationValid ? (
                <div className="filter-error-message">
                  Please select at least one filter from each group
                </div>
              ) : !isStatusValid || !isLocationValid ? (
                <div>
                  {!isStatusValid && (
                    <div className="filter-error-message">
                      Please select at least one option: Ongoing or Complete
                    </div>
                  )}
                  {!isLocationValid && (
                    <div className="filter-error-message">
                      Please select at least one option: National or
                      International
                    </div>
                  )}
                </div>
              ) : filteredData.length > 0 ? (
                <>
                  <ProjectPosts frames={currentPublication} />
                  <Pagination
                    currentPage={currentPage}
                    itemsPerPage={publicationPerPage}
                    totalItems={filteredData.length}
                    onPageChange={setCurrentPage}
                  />
                </>
              ) : (
                <div>No projects found.</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectV2;
