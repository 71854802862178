import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../components/global-components/Footer";
import { usePageData } from "../data/usePageData";
import HeaderV2 from "../components/section-components/Header-v2";

const Home2ShowNoImg = () => {
  const { pageData } = usePageData();
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          "http://10.70.3.77:3000/alunos/obterBolseirosUI?ui=930",
          {
            headers: {
              "api-key":
                "vP00UVadsafca43SFAWfcfaGpFtIfnr1PRdPndXhfyKlroWOYwfyc5Fsdj1v",
            },
          }
        );
        setPosts(res.data.payload);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  return (
    <>
      <div className="overlay">
        <HeaderV2
          pageData={pageData}
          handleImageLoad={handleImageLoad}
          title={""}
          size="450px"
        />
        {isLoading ? (
          <div className="preloader">
            <div className="main-circle">
              <div className="green-circle">
                <div className="brown-circle"></div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <h1>TEST</h1>
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default Home2ShowNoImg;
