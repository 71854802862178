import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";

export const ProjectPosts = ({ frames, loading }) => {
  let valorApoio = 0;
  let valorProjeto = 0;
  Moment.locale("pt");

  console.log("postcadentro", frames);

  if (loading) {
    return (
      <ul className="item justify-content-center">
        <h3 style={{ marginTop: "300px", height: "100%" }}>Loading...</h3>
      </ul>
    );
  }

  let toEUR = new Intl.NumberFormat("pt-PT", {
    style: "currency",
    currency: "EUR",
  });

  return (
    <div>
      {frames.map((post) => {
        const valorApoio = post.ipvc_budget
          ? toEUR.format(post.ipvc_budget)
          : "";
        const valorProjeto = post.total_budget
          ? toEUR.format(post.total_budget)
          : "";

        let formatedStartDate = new Date(post.start_date).toLocaleDateString(
          "pt-PT"
        );
        let formatedEndDate = new Date(post.end_date).toLocaleDateString(
          "pt-PT"
        );

        return (
          <div className="container-fluid" key={post.id}>
            <div className="row">
              <div className="col-12">
                <div className="cardPubs" style={{ display: "flex" }}>
                  <div className="col-xl-9 col-lg-9">
                    <div className="cardPubs-body">
                      <div className="cardPubs-title">
                        <Link to={`/project-details/${post.id}`}>
                          <span style={{ fontSize: "20px" }}>
                            <b>{post.description}</b>
                          </span>
                        </Link>
                      </div>
                      <div className="cardPubs-text">
                        {post.summary && (
                          <div>
                            <br />
                            <span>{post.summary}</span>
                            <br />
                            <br />
                          </div>
                        )}
                        <label style={{ fontSize: "15px", color: "#53565A" }}>
                          <b>Start Date:</b>
                        </label>
                        <span> {formatedStartDate}</span>
                        <label
                          style={{
                            fontSize: "15px",
                            color: "#53565A",
                            marginLeft: "10px",
                          }}
                        >
                          <b>End Date:</b>
                        </label>
                        <span> {formatedEndDate}</span>
                        <br />
                        {valorApoio && (
                          <>
                            <label
                              style={{ fontSize: "15px", color: "#53565A" }}
                            >
                              <b>IPVC Budget:</b>
                            </label>
                            <span> {valorApoio}</span>
                          </>
                        )}
                        {valorProjeto && (
                          <>
                            <label
                              style={{
                                fontSize: "15px",
                                color: "#53565A",
                                marginLeft: "10px",
                              }}
                            >
                              <b>Total Budget:</b>
                            </label>
                            <span> {valorProjeto}</span>
                          </>
                        )}
                        {post.url && <a href={post.url}>Ver mais</a>}
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectPosts;
