import React, { useState, useEffect } from "react";
import axios from "axios";
import { withRouter, Link, useHistory } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

function SingleMember(props) {
  const history = useHistory();

  const [publications, setPublications] = useState([]);
  const [projects, setProjects] = useState(null);
  const [programaDoutoral, setProgramaDoutoral] = useState([]);
  const [programaInvestigador, setProgramaInvestigador] = useState([]);
  const [advisorNames, setAdvisorNames] = useState([]);
  const [investigador, setInvestigador] = useState([]);
  const defaultImg = "https://adit.ipvc.pt/backend/backend/fotos/adit/test.svg";
  const mockTipos = [
    "Journal article",
    "Journal issue",
    "Conference abstract",
    "Conference poster",
    "Conference paper",
    "Book",
    "Edited book",
    "Book chapter",
    "Other",
  ];
  const [previousUser, setPreviousUser] = useState([]);
  const [nextUser, setNextUser] = useState([]);

  const data = JSON.stringify({
    data_prevista_inicio: "2000-01-01",
    data_prevista_fim: "",
  });
  const request = {
    method: "post",
    url:
      "https://adit.ipvc.pt/modulosipvc_producao/rest-api-slimv3/api/public/projetos/investigador/" +
      props.id,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  useEffect(() => {
    if (props.id !== undefined) {
      axios
        .get(window.config.DEV_LINK + "/output/investigador/" + props.id)
        .then((response) => {
          setPublications(response.data.payload);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      if (props.user?.EMAIL) {
        const fetchDataByEmail = async () => {
          try {
            const email = props.user.EMAIL; // Replace with the desired email
            const response = await axios.get(
              `https://adit.ipvc.pt/modulosipvc_producao/rest-api-slimv3/api/proxy/by_email/` + email
            );
            const sortedProjects = response.data.data.sort((a, b) => {
              return new Date(b.start_date) - new Date(a.start_date);
            });
            // Handle the response data as needed
            setProjects(sortedProjects);
          } catch (error) {
            console.error("Failed to fetch data by email:", error);
          }
        };

        fetchDataByEmail();
      }

      axios
        .get(
          window.config.DEV_LINK +
            "/investigador/programaDoutoral?INVESTIGADOR_ID=" +
            props.id
        )
        .then((response) => {
          setProgramaDoutoral(response.data.programa_doutoral[0]);
          setProgramaInvestigador(response.data.programa_investigador[0]);
          setAdvisorNames(
            response.data.programa_investigador[0].ORIENTADORES_EXTERNOS
          );
          const advisorNumber = JSON.parse(
            response.data.programa_investigador[0].ORIENTADORES_IPVC[0]
          );

          return axios.get(
            window.config.DEV_LINK + "/investigador/getById/" + advisorNumber
          );
        })
        .then((response) => {
          setInvestigador(response.data.payload[0]);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [props.id, props.user?.EMAIL]);

  //regex to remove full stops from names, CARE as it may cause some trouble
  if (publications && publications.length) {
    publications.forEach((element) => {
      const regex = /[.]/g;
      element.autores = element.autores.replace(regex, "");
    });
  }

  const filteredPublications = {
    "Journal article": [],
    "Journal issue": [],
    "Conference abstract": [],
    "Conference poster": [],
    "Conference paper": [],
    Book: [],
    "Edited book": [],
    "Book chapter": [],
    Other: [],
  };
  if (publications) {
    publications.forEach((publication) => {
      const type = publication.tipo_descr_en;
      if (mockTipos.includes(type)) {
        filteredPublications[type].push(publication);
      } else {
        filteredPublications["Other"].push(publication);
      }
    });
  }

  const currID = window.location.href.split("/")[4];

  useEffect(() => {
    let apreviousUser = null;
    let anextUser = null;

    for (let i = 0; i < props.posts.length; i++) {
      let element = props.posts[i];
      if (
        element.USERNAME === currID ||
        element.INVESTIGADOR_ID === currID ||
        element.ID === currID
      ) {
        apreviousUser = i > 0 ? props.posts[i - 1] : null;
        anextUser = i < props.posts.length - 1 ? props.posts[i + 1] : null;
        break;
      }
    }

    setPreviousUser(apreviousUser);
    setNextUser(anextUser);
  }, [props.posts, currID]);

  const handlePreviousUser = () => {
    if (previousUser) {
      const username =
        previousUser.USERNAME ||
        previousUser.INVESTIGADOR_ID ||
        previousUser.ID;
      history.push(`/team-details/${username}`, {
        userID: previousUser.ID,
        page_link: username,
        posts: props.posts,
      });
      window.location.reload();
    }
  };

  const handleNextUser = () => {
    if (nextUser) {
      const username =
        nextUser.USERNAME || nextUser.INVESTIGADOR_ID || nextUser.ID;
      history.push(`/team-details/${username}`, {
        userID: nextUser.ID,
        page_link: username,
        posts: props.posts,
      });
      window.location.reload();
    }
  };

  return (
    <>
      <>
        {/* <!-- Start menubar area --> */}
        <section className="navbar-teams">
          <div className="container ">
            <div className="row" style={{ alignContent: "center" }}>
              <div className="col-2">
                <button className="nextBtn" onClick={handlePreviousUser}>
                  &#8249;
                </button>
              </div>
              <div className="col-8">
                <ul className="team-bar">
                  {window.config.RESEARCH_AREAS_ASSOCIATION === 1 &&
                    props.user.lt_descr && (
                      <div className="col-xl-2 col-lg-2">
                        <li>
                          <AnchorLink offset="220" href="#RA">
                            Research Area
                          </AnchorLink>
                        </li>
                      </div>
                    )}

                  {props.user.short_bio && (
                    <div className="col-xl-2 col-lg-2">
                      <li>
                        <AnchorLink offset="220" href="#AM">
                          About me
                        </AnchorLink>
                      </li>
                    </div>
                  )}

                  {props.user.TIPO === "Aluno de Doutoramento" ||
                    (props.user.TIPO ===
                      "Aluno de Doutoramento / Colaborador" &&
                      programaDoutoral &&
                      programaDoutoral.ID &&
                      programaInvestigador &&
                      programaInvestigador.ID && (
                        <div className="col-xl-2 col-lg-2">
                          <li>
                            <AnchorLink offset="220" href="#PHD">
                              PHD Program
                            </AnchorLink>
                          </li>
                        </div>
                      ))}

                  {publications !== null && publications.length > 0 && (
                    <div className="col-xl-2 col-lg-2">
                      <li>
                        <AnchorLink offset="220" href="#PU">
                          Publications
                        </AnchorLink>
                      </li>
                    </div>
                  )}

                  {props.theses !== null && props.theses.length > 0 && (
                    <div className="col-xl-2 col-lg-2">
                      <li>
                        <AnchorLink offset="220" href="#TH">
                          Thesis and Dissertations
                        </AnchorLink>
                      </li>
                    </div>
                  )}

                  {projects != null &&
                    projects.length > 0 &&
                    projects !==
                      "Não foi possivel obter dados do investigador." && (
                      <div className="col-xl-2 col-lg-2">
                        <li>
                          <AnchorLink offset="220" href="#PR">
                            Projects
                          </AnchorLink>
                        </li>
                      </div>
                    )}
                </ul>
              </div>
              <div className="col-2">
                <button className="nextBtn" onClick={handleNextUser}>
                  &#8250;
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End menubar area --> */}
      </>
      <section className="team-detail pt-120 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 ">
              <div
                className="team-inner inner-shadow"
                style={{ marginTop: "19px" }}
              >
                {props.user.foto_path === null ||
                props.user.foto_path === "" ? (
                  <img
                    src={defaultImg}
                    className="img-fluid"
                    alt="Team"
                    style={{ borderRadius: "25px" }}
                  />
                ) : (
                  <img
                    src={window.config.PEOPLE_FOTOS + props.user.foto_path}
                    className="img-fluid"
                    alt="Team"
                    style={{ borderRadius: "25px" }}
                  />
                )}
              </div>
            </div>
            <div className="col-xl-8 team-inner-contacts-square">
              <div className="team-inner inner-shadow">
                <div className="single-item mt-md-30 personal-info ml">
                  <div className="fig-detail text-center">
                    <h3>{props.user.NOME}</h3>
                    <h6>{props.user.cargo}</h6>
                    <br></br>Orcid ID:
                    <a
                      href={"https://orcid.org/" + props.user.ORCID_ID}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {props.user.ORCID_ID}
                    </a>
                    <br />
                    Scopus ID:
                    <a
                      href={
                        "https://www.scopus.com/authid/detail.uri?authorId=" +
                        props.user.SCOPUS_ID
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {props.user.SCOPUS_ID}
                    </a>
                    <br />
                    Ciência ID:
                    <a
                      href={
                        "https://www.cienciavitae.pt/portal/en/" +
                        props.user.CIENCIA_VITAE_ID
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {props.user.CIENCIA_VITAE_ID}
                    </a>
                  </div>

                  <div className="fig-detail text-center mt-80">
                    <h4>Contact</h4>
                    <p style={{ textAlign: "center" }}>
                      <span>{props.user.EMAIL_EXTERNO}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12" style={{ marginTop: "50px" }}>
            <div
              className="team-inner right-part"
              style={{
                textAlign: "justify",
                textJustify: "inter-word",
                marginRight: "85px",
              }}
            >
              {window.config.RESEARCH_AREAS_ASSOCIATION === 1 &&
                props.user.lt_descr && (
                  <div className="single-item mt-30">
                    <div className="item-title">
                      <h4 id="RA">Research Area</h4>
                    </div>
                    <p className="item justify">{props.user.lt_descr}</p>
                  </div>
                )}

              {props.user.short_bio && (
                <div className="single-item mt-30">
                  <div className="item-title">
                    <h4 id="AM">About me</h4>
                  </div>
                  <div className="short-bio">
                    <p className="item justify">{props.user.short_bio}</p>
                  </div>
                </div>
              )}

              {props.user.TIPO === "Aluno de Doutoramento" ||
              (props.user.TIPO === "Aluno de Doutoramento / Colaborador" &&
                programaDoutoral &&
                programaDoutoral.ID &&
                programaInvestigador &&
                programaInvestigador.ID) ? (
                <div className="single-item mt-30">
                  <div className="item-title">
                    <h4 id="PHD">PHD Program</h4>
                  </div>
                  <div>
                    <p style={{ color: "black" }}>
                      <b>Program:</b> <span>{programaDoutoral.NOME}</span>
                    </p>
                    <p style={{ color: "black" }}>
                      <b>Institution:</b>{" "}
                      <span>{programaDoutoral.NOME_INSTITUICAO}</span>
                    </p>
                    <p style={{ color: "black" }}>
                      <b>Thesis Title:</b>{" "}
                      <span>{programaInvestigador.TITULO_TESE}</span>
                    </p>
                    <p style={{ color: "black" }}>
                      <b>Start Date: </b>
                      {programaInvestigador.DATA_INI}
                    </p>
                    <Link
                      to={{
                        pathname:
                          "/team-details/" +
                          (investigador.USERNAME
                            ? investigador.USERNAME
                            : investigador.INVESTIGADOR_ID),
                        state: {
                          userID: investigador.ID,
                          page_link: investigador.USERNAME,
                        },
                      }}
                    >
                      <p style={{ color: "black" }}>
                        <b>IPVC Supervisor: </b>{" "}
                        <span
                          onClick="window.location.reload()"
                          style={{ color: "#0000EE" }}
                        >
                          {investigador.NOME}
                        </span>
                      </p>
                    </Link>
                    <p style={{ color: "black" }}>
                      <b>External Supervisor: </b>
                      {advisorNames.map((item) => {
                        return (
                          <span key={item}>
                            {item} {}
                          </span>
                        );
                      })}
                    </p>

                    <br />
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="single-item mt-30">
                <div className="item-title">
                  <h4 id="PU">Publications</h4>
                </div>
                {Object.keys(filteredPublications).map(
                  (type, index) =>
                    // Check if the category is not empty before rendering
                    filteredPublications[type].length > 0 && (
                      <div key={index}>
                        <h5 className="myTitle">{type}</h5>
                        {filteredPublications[type].map((publication) => (
                          <div className="eachPub" key={publication.id}>
                            <p style={{ color: "black" }}>
                              {publication.autores
                                ? publication.autores
                                : props.user.nome}{" "}
                              {}({publication.ano}) {publication.artigo}
                              <i>
                                {publication.venue
                                  ? ". " + publication.venue + "."
                                  : ""}
                              </i>
                              {publication.sjr
                                ? "    SJR: " + publication.sjr
                                : ""}
                              {publication.jcr
                                ? " JCR: " + publication.jcr
                                : ""}
                              {publication.doi ? (
                                <a
                                  href={`https://doi.org/${publication.doi}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  https://doi.org/{publication.doi}{" "}
                                </a>
                              ) : (
                                ""
                              )}
                              {publication.IS_SCOPUS && (
                                <div className="team-details-scopus">
                                  <b>Scopus</b>
                                </div>
                              )}
                              {publication.IS_WOF && (
                                <div className="team-details-wof">
                                  Web Of Science
                                </div>
                              )}
                              {publication.IS_CROSSREF && (
                                <div className="team-details-crossref">
                                  CrossRef
                                </div>
                              )}
                              {publication.scimago_quartil === "Q1" && (
                                <div className="team-details-scimagoQ1-2">
                                  Scimago Q1
                                </div>
                              )}
                              {publication.scimago_quartil === "Q2" && (
                                <div className="team-details-scimagoQ1-2">
                                  Scimago Q2
                                </div>
                              )}
                              {publication.scimago_quartil === "Q3" && (
                                <div className="team-details-scimagoQ3-4">
                                  Scimago Q3
                                </div>
                              )}
                              {publication.scimago_quartil === "Q4" && (
                                <div className="team-details-scimagoQ3-4">
                                  Scimago Q4
                                </div>
                              )}
                            </p>
                            <br></br>
                          </div>
                        ))}
                      </div>
                    )
                )}
              </div>
              {props.theses !== null && props.theses.length > 0 && (
                <div className="single-item mt-30">
                  <div className="item-title">
                    <h4 id="TH">Thesis and Dissertations</h4>
                  </div>
                  {props.theses.map((theses) => (
                    <div className="eachPub" key={theses.TID}>
                      <p style={{ color: "black" }}>
                        {theses.NomeCompleto
                          ? theses.NomeCompleto + ".\u00A0"
                          : ""}
                        {theses.TemaTese + ".\u00A0"}[
                        {theses.TipoTese === "Mestrado"
                          ? "Dissertação de Mestrado" + ",\u00A0"
                          : "Tese de Doutoramento" + ",\u00A0"}
                        {theses.UnidadeOrganica}].
                        {theses.URL}
                      </p>
                      <br></br>
                    </div>
                  ))}
                </div>
              )}
              {projects &&
                projects != null &&
                projects.length !== 0 &&
                projects !==
                  "Não foi possivel obter dados do investigador." && (
                  <div className="single-item mt-30">
                    <div className="item-title">
                      <h4 id="PR">Projects</h4>
                    </div>
                    {projects.map((item) => {
                      return (
                        <div key={item.id}>
                          <p className="myTitle">{item.description} </p>
                          <p style={{ color: "black" }}>
                            Funded by: {item.aviso} <br></br>Start:{" "}
                            {new Date(item.start_date).toLocaleDateString(
                              "pt-pt"
                            )}{" "}
                            | End:{" "}
                            {new Date(item.end_date).toLocaleDateString(
                              "pt-pt"
                            )}{" "}
                            <br></br>
                            {item.ipvc_budget
                              ? ` IPVC Budget: ${parseFloat(
                                  item.ipvc_budget
                                ).toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}`
                              : ""}{" "}
                            {item.total_budget
                              ? ` | Total Budget: ${parseFloat(
                                  item.total_budget
                                ).toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}`
                              : ""}{" "}
                          </p>
                          <Link
                            to={{
                              pathname: "/project-details/" + item.id,
                              state: { projectID: item.id },
                            }}
                          >
                            <p className="hiperlink">More details </p>
                            <br />
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(SingleMember);
