import React from "react";
import { Link, withRouter } from "react-router-dom";

function SingleProjectInfo(props) {
  const currentDate = new Date();
  const coordinators = props.projeto.team.filter(
    (member) => member.role === "Coordenador Institucional"
  );

  return (
    <>
      <div className="team-inner inner-shadow">
        <div className="fig-detail text-center">
          <h3>{props.projeto.description}</h3>
          <p>{props.research_area}</p>
          <div>
            <b>Coordinator(s):</b>
            {coordinators.length > 0 ? (
              <ul>
                {coordinators.map((coordinator, index) => {
                  // Check if the coordinator's name has the expected format.
                  let webSiteName = coordinator.name;
                  let fullName = coordinator.name;
                  if (coordinator.name.includes(" - ")) {
                    [webSiteName, fullName] = coordinator.name.split(" - ");
                  }
                  return (
                    <li key={index}>
                      <Link
                        to={{
                          // Adjust the pathname as needed. Here I'm using the coordinator's email for uniqueness.
                          pathname: `/team-details/${webSiteName}`,
                          state: { username: webSiteName },
                        }}
                        onClick={(e) => {
                          e.preventDefault(); // Prevent React Router's navigation
                          window.location.href = `/team-details/${webSiteName}`; // Force full reload to the new URL
                        }}
                      >
                        {fullName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <span>No coordinator found</span>
            )}
          </div>
        </div>
      </div>
      <div className="team-inner inner-shadow">
        <div className="single-item mt-md-30 personal-info">
          <div className="item-title">
            <h4>State</h4>
          </div>
          <ul>
            <li>
              <p>
                {props.projeto.on_going === true ? <span>Ongoing</span> : <span>Completed</span>}
              </p>
            </li>
          </ul>
        </div>
        <div className="single-item mt-md-30 personal-info">
          <div className="item-title">
            <h4>Funded By</h4>
          </div>
          <ul>
            <li>
              <p>
                <span>{props.projeto.aviso}</span>
              </p>
            </li>
          </ul>
        </div>
        <div className="single-item mt-md-30 personal-info">
          <div className="item-title">
            <h4>Date</h4>
          </div>
          <ul>
            <li>
              <p>
                <span>
                  <b>Start Date: </b>
                  {new Date(props.projeto.start_date).toLocaleDateString(
                    "pt-pt"
                  )}
                </span>
              </p>
            </li>
            <br />
            <li>
              <p>
                <span>
                  <b>End Date: </b>
                  {new Date(props.projeto.end_date).toLocaleDateString("pt-pt")}
                </span>
              </p>
            </li>
          </ul>
        </div>
        {props.projeto.ipvc_budget && props.projeto.ipvc_budget != 0 ? (
          <div className="single-item mt-md-30 personal-info">
            <div className="item-title">
              <h4>Budget IPVC</h4>
            </div>
            <ul>
              <li>
                <p>
                  <span>
                    {parseFloat(props.projeto.ipvc_budget).toLocaleString(
                      "pt-PT",
                      {
                        style: "currency",
                        currency: "EUR",
                      }
                    )}
                  </span>
                </p>
              </li>
            </ul>
          </div>
        ) : null}
        {props.projeto.total_budget && (
          <div className="single-item mt-md-30 personal-info">
            <div className="item-title">
              <h4>Budget Total</h4>
            </div>
            <ul>
              <li>
                <p>
                  {parseFloat(props.projeto.total_budget).toLocaleString(
                    "pt-PT",
                    {
                      style: "currency",
                      currency: "EUR",
                    }
                  )}
                </p>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default withRouter(SingleProjectInfo);
