import React, { useState, useEffect } from "react";
import axios from "axios";

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [documentsType, setDocumentsType] = useState([]);
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const res = await axios.get(
        `${window.config.DEV_LINK}/documentos/getById/${window.config.id}`
      );
      const sortedDocuments = res.data.payload.sort((a, b) => b.ANO - a.ANO);
      setDocuments(sortedDocuments);
    };

    const fetchDocumentsType = async () => {
      const res2 = await axios.get(`${window.config.DEV_LINK}/documentos/tipo`);
      setDocumentsType(res2.data.payload);
    };

    const fetchLanguage = async () => {
      const res3 = await axios.get(
        `${window.config.DEV_LINK}/documentos/idioma`
      );
      setLanguage(res3.data.payload);
    };

    fetchDocuments();
    fetchDocumentsType();
    fetchLanguage();
  }, []);

  const getAcronym = (id_lang) => {
    if (language) {
      const matchingLanguage = language.find(
        (lang) => lang.ID_IDIOMA === id_lang
      );
      if (matchingLanguage) {
        return matchingLanguage.INICIAIS;
      }
    }
    return "";
  };

  return (
    <section className="blog-detail pt-121">
      <div className="container">
        <div className="row">
          <div className="col-lg-12" align="justify">
            {documentsType && (
              <div className="documents">
                {documentsType.map((type) => {
                  const filteredDocuments = documents.filter(
                    (doc) => doc.TIPO_DOC_ID === type.ID
                  );

                  return (
                    <div key={type.ID}>
                      <h2>{type.descricao_en}</h2>
                      <hr />
                      <div className="documents_box">
                        {filteredDocuments.map((doc, index) => (
                          <div className="alternateColor" key={index}>
                            <a
                              className="documents"
                              href={`https://adit.ipvc.pt/modulosipvc_producao/frontend/documentos/modulo_documentos/${doc.PATH}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>
                                {type.descricao_en} - {doc.ANO} (
                                {getAcronym(doc.ID_IDIOMA)})
                              </p>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Documents;
