import React from "react";

const PaginationFrame = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  if (totalPages <= 1) return null;

  const pagesToShow = 2;
  let startPage = currentPage - Math.floor(pagesToShow / 2);
  let endPage = currentPage + Math.floor(pagesToShow / 2);

  if (startPage <= 0) {
    endPage -= startPage - 1;
    startPage = 1;
  }

  if (endPage > totalPages) {
    endPage = totalPages;
  }

  return (
    <nav className="nav justify-content-center">
      <ul className="pagination justify-content-center">
        {startPage > 1 && (
          <>
            <li key={1} className="page-item">
              <button onClick={() => onPageChange(1)} className="page-link">
                1
              </button>
            </li>
            <li className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          </>
        )}
        {pageNumbers.slice(startPage - 1, endPage).map((number) => (
          <li key={number} className="page-item">
            <button
              onClick={() => {
                onPageChange(number);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className={`page-link ${number === currentPage ? "active" : ""}`}
            >
              {number}
            </button>
          </li>
        ))}
        {endPage < totalPages && (
          <>
            <li className="page-item disabled">
              <span className="page-link">...</span>
            </li>
            <li key={totalPages} className="page-item">
              <button
                onClick={() => onPageChange(totalPages)}
                className="page-link"
              >
                {totalPages}
              </button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default PaginationFrame;
